import React from "react"
import styles from "../css/postTemplate.module.css"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Carousel4 from '../components/Projects/carousel/carousel4'

export const query = graphql`
 {
  file(relativePath: {eq: "portadas.jpg"}){
    childImageSharp{
      fluid{
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
}

`

const Digipak = ({ data }) => {
  const author = 'Yukuri'
  const title = 'Digipak Skott'
  return (
    <Layout>
      <section className={styles.template}>
        <Link to="/projects" className={styles.link}>
          {" "}
            back to projects
          </Link>
        <div className={styles.info}>
          <h1>{title}</h1>
          <h4>
            <span>by {author}</span>
          </h4>
        </div>
        <Image fluid={data.file.childImageSharp.fluid} />          <div className={styles.content}>
          <div>Este fue un proyecto para clase. El proyecto era la creación de un digipack y el CD del interior.
          Me encanta la música de Skott una artista sueca de música indie, asi que pense que era la mejor
          oportunidad para hacer una portada ficticia de su trabajo.
          Siguiendo la estética de sus LP's realicé una ilustración con estética Art Nouveau para la
          portada y la decoración del interior.

          También incluye el diseño de un vinilo, siguiendo la misma línea de diseño.

Proyecto realizado con Procreate, Adobe Photoshop y Adobe Illustrator.</div>

        </div>                    <Carousel4 />

      </section>
    </Layout>
  )
}

export default Digipak
